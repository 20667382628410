//* 基本標籤 reset
*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: "Roboto" !important;
    font-weight: bolder;
}
//* 字型
.text-Nunito-Weight {
    font-family: "Nunito", sans-serif !important;
}

div,
p,
span,
a,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-weight: bolder;
}

.btn {
    font-weight: bolder;
}

.position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

table {
    td {
        color: $primary !important;
        padding: 10px 0;
    }
    thead {
        border-bottom: 1.5px solid $primary;
    }
}
