//@ 進度條
.challenge {
    table thead th {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    &-header {
        text-align: center;
        background: $secondary;
        border-bottom-right-radius: 100px;
        padding: 15px 0;
        text-align: center;
        margin-bottom: 20px;
        h2 {
            margin-bottom: 8px;
            color: $primary;
        }
        p {
            font-size: 14px;
            color: #000;
        }
    }
    &-main-img {
        width: 160px;
        @include view-768 {
            width: 126px;
        }
    }
    &-success-img {
        width: 160px;
        @include view-768 {
            width: 146px;
        }
    }
    &-main-img_rank {
        width: 50%;
        @include view-768 {
            width: 30%;
        }
    }
    &-main-img_award {
        width: 40%;
        @include view-768 {
            width: 30%;
        }
        @include view-992 {
            width: 20%;
        }
    }
    &-title {
        font-size: 32px;
        font-weight: bolder;
        margin-bottom: 12px;
        letter-spacing: 3px;
    }
    &-subtitle {
        line-height: 24px;
        letter-spacing: 3px;
    }
    &-progress {
        width: 100%;
        height: 20px;
        background-color: $primary-light;
        border: 4px solid #fff;
        border-radius: 30px;
    }
    &-progress-goal {
        width: 100px;
        height: 100px;
        border: 4px solid #fff;
        background: linear-gradient(#c4d7e5, #dae2e9);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        bottom: -28px;
        @include view-992 {
            width: 90px;
            height: 90px;
        }
        @include view-768 {
            width: 74px;
            height: 74px;
        }
        .goal-text {
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            color: $primary;
            font-weight: bolder;
        }
    }
    &-progress-goal-success {
        background: linear-gradient(180deg, #ffe707 0%, #f5a100 100%) !important;
    }
    &-social {
        .strava-icon {
            width: 50px;
            height: 50px;
            background: #fc4c01;
            border-radius: 50%;
            position: relative;
            &::before {
                // content: url("../../images/challengesofGolden/icon/strava.svg");
                width: 100%;
                height: 100%;
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &-type-button > button {
        border-bottom: 2px solid transparent;
        padding-bottom: 4px;
    }
    &-award-img {
        width: 90%;
        @include view-768 {
            width: 56%;
        }
        @include view-992 {
            width: 75%;
        }
    }
    &-not {
        position: relative;
        &:hover {
            &::before {
                content: attr(title);
                color: $primary;
                font-weight: bolder;
                padding: 10px;
                margin: 0 5px;
                background: #fff;
                position: absolute;
                top: -50px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
                white-space: nowrap;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 46%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                border-top: 8px solid #fff;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
        }
    }
}
.progress {
    border-radius: 30px;
    border: 4px solid #fff;
    background: linear-gradient(#c4d7e5, #dae2e9);
    height: 28px;
    width: 100%;
    @include view-576 {
        width: 90%;
    }
    @include view-768 {
        width: 95%;
    }
    &-bar {
        background: linear-gradient(180deg, #ffe707 0%, #f5a100 100%);
        border-radius: 30px;
        color: $primary;
        padding: 10px;
    }
}
.button-group {
    img {
        margin-right: 10px;
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        padding: 12px 0;
        height: 55px;
        @include view-576 {
            font-size: 16px;
        }
    }
    span {
        // width: 50%;
        margin-top: 5px;
    }
}

//@ 圓圈
.circle-box {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 10px;
}

//@漸層按鈕
.btn-gradient {
    &-golden {
        color: #fff;
        background: linear-gradient(#f3c216, #be901a, #ffc530);
        &:hover {
            color: #fff;
        }
    }
    &-gary {
        color: #fff;
        background: linear-gradient(#c4c9c9, #868686, #c0c9c9);
        &:hover {
            color: #fff;
        }
    }
    &-orange {
        color: #fff;
        background: linear-gradient(#cd7739, #bd4917, #e97f33);
        &:hover {
            color: #fff;
        }
    }
    &-purple {
        color: #fff;
        background: linear-gradient(#877ad5, #7c3fba, #6462cb);
        &:hover {
            color: #fff;
        }
    }
    &-blue {
        color: #fff;
        background: linear-gradient(#006096, #053670, #00638e);
        &:hover {
            color: #fff;
        }
    }
}

.text-gradient {
    @mixin basic-setting {
        color: transparent;
        background-clip: text;
        font-size: 35px;
    }
    &-golden {
        background: linear-gradient(#f3c216, #be901a, #ffc530);
        @include basic-setting;
    }
    &-gary {
        background: linear-gradient(#c4c9c9, #868686, #c0c9c9);
        @include basic-setting;
    }
    &-orange {
        background: linear-gradient(#cd7739, #bd4917, #e97f33);
        @include basic-setting;
    }
    &-purple {
        background: linear-gradient(#877ad5, #7c3fba, #6462cb);
        @include basic-setting;
    }
    &-blue {
        background: linear-gradient(#006096, #053670, #00638e);
        @include basic-setting;
    }
}

//@ 刪除 icon
.del-icon {
    filter: drop-shadow(1000px 0 0 #c80000);
    transform: translate(-1000px);
}
