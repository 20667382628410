.header {
    text-align: center;
    background: $secondary;
    border-bottom-right-radius: 100px;
    padding: 15px 0;
    text-align: center;
    margin-bottom: 32px;
    h2 {
        margin-bottom: 8px;
        color: $primary;
    }
    p {
        font-size: 16px;
        color: #000;
    }
}
