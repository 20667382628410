//RWD media 由小寫到大
@mixin view-1920 {
    @media (max-width: 1920px) {
        @content;
    }
}
@mixin view-1400 {
    @media (max-width: 1400px) {
        @content;
    }
}
@mixin view-1200 {
    @media (max-width: 1200px) {
        @content;
    }
}
@mixin view-992 {
    @media (max-width: 992px) {
        @content;
    }
}
@mixin view-768 {
    @media (max-width: 768px){
        @content;
    }
}
@mixin view-576 {
    @media (max-width: 576px) {
        @content;
    }
}
@mixin view-414 {
    @media (max-width: 414px) {
        @content;
    }
}
@mixin view-390 {
    @media (max-width: 390px) {
        @content;
    }
}
