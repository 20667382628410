//@ react-datePicker 日期選擇器
.react-datepicker__triangle {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    // width: 45% !important;
    padding: 5px !important;
    border: 0 !important;
    margin: 5px 15px !important;
    font-size: 16px;
    font-weight: bolder;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    background: #f4f4f4;
    border-radius: 5px;
}

.react-datepicker-popper {
    z-index: 49 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: $primary;
    &:hover {
        background-color: $primary;
    }
}


//@ sweet alert
.swal2-cancel{
    border: 1px solid $primary !important;
    background: transparent !important;
    color: $primary !important;
    font-weight: bolder !important;
}

.swal2-styled{
    border-radius: 10px !important;
}